#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparan arka plan */
  z-index: 9999; /* Üstte görünmesi için */
}

.spinner-border {
  width: 4rem;
  height: 4rem;
  border-width: 0.5rem;
}
