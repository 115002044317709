.heart{
	width: 60px;
    height: 60px;
    display: inline-block;
    background: url('../images/like.png');
    cursor: pointer;
    margin: -25px -15px;
}
.heart-blast{
	background-position: -1680px 0 !important;
    transition: background 1s steps(28);
}
.widget-buyer {
    .media {
        img {
            width: 30px;
        }
        .media-body {
            h5 {
                font-size: 1rem;
                margin-bottom: 0px;
            }
            p {
                font-size: 13px;
            }
        }
    }
}
.dlab-scroll{
	overflow-y: scroll;
}
.recentOrderTable {
    table {
        margin-bottom: 0;
        overflow: hidden;
        thead {
            th {
                font-size: 1rem;
            }
        }
        tbody {
            td {
                color: var(--text);
                font-weight: 500;
                @at-root [data-theme-version="dark"] & {
                    color: $d-ctl;
                }
                img {
                    border-radius: 50px;
                }
                .custom-dropdown {
                    i {
                        padding: 0 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.map-list {
    i {
        font-size: 12px;
    }
    .us {
        color: $success;
    }
    .in {
        color: $warning;
    }
    .uk {
        color: $info;
    }
    .tr {
        color: #8B572A;
    }
    .rs {
        color: $success;
    }
}

.widget-team {
    .media {
        img {
            width: 35px;
        }
        .media-body {
            p {
                font-weight: 500;
                span {
                    color: var(--text);
                    @at-root [data-theme-version="dark"] & {
                        color: $white;
                    }
                }
            }
        }
    }
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent!important;
    opacity: 0.9;
}

.chart-link {
    @include respond('phone') {
        display: flex;
        align-items: center;
    }
    @include respond('tab-land') {
        display: flex;
        align-items: center;
    }
    @include respond('desktop') {
        display: flex;
        align-items: center;
    }
    .week-link {
        display: inline-block;
    }
    a {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        margin-#{$dlab-pos-right}: 10px;
        i {
            font-size: 13px;
            &.text-primary {
                color: var(--primary);
            }
            &.text-muted {
                color: #DAE2F3!important;
            }
        }
    }
    .form-control {
        max-width: 97px;
        display: inline-block;
    }
}

#activeUser {
    height: 180px !important;
}

span#counter {
    font-size: 30px;
    font-weight: 700;
    color: var(--primary);
}

.social-graph-wrapper {
    text-align: center;
    padding: 20px;
    position: relative;
    color: $white;
    border-top-#{$dlab-pos-left}-radius: 0.25rem;
    border-top-#{$dlab-pos-right}-radius: 0.25rem;

    &.widget-facebook {
        background-color: $facebook;
    }
    &.widget-twitter {
        background-color: $twitter;
    }
    &.widget-linkedin {
        background-color: $linkedin;
    }
    &.widget-googleplus {
        background-color: $google-plus;
    }
    .s-icon {
        font-size: 24px;
        position: relative;
        padding: 0 10px;
    }
}

// Form Head
.form-head{

	.breadcrumb{
		background:transparent;
		padding:0;
	}

	&.style-1 {
		.search-area {
			box-shadow: unset;
			border: 1px solid var(--border);
		}
		.input-group-text {
			background: var(--card);
			padding: 0 20px;
			border: 0;
		}
	}
	.search-area{
		max-width:400px;
		box-shadow: 0 15px 25px 0 rgba(0,0,0,0.06);
		border-radius: $radius;
		@include respond('laptop') {
			max-width:250px;
		}
		@include respond('phone') {
			max-width:250px;
		}

		.form-control{
			background: transparent;
			border: 0;
			height: 40px;
			padding: 10px 20px;
			font-size: 1rem;
			@include respond('laptop') {
				padding: 10px 15px;
				font-size: 13px;
			}
		}
		.input-group-append .input-group-text{
			background:$white;
			padding: 0 20px;
			@include respond('laptop') {
				padding: 0 15px;
			}
			i {
				font-size: 24px;
				color:var(--text);
				@include respond('laptop') {
					font-size: 18px;
				}
			}
		}
	}
	.dropdown {
		.btn{

			i{
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				display: inline-block;
			}
		}
	}
	.btn i{
		line-height: 1;
		transform: scale(1.3);
		display: inline-block;
		margin-#{$dlab-pos-right}: 5px;
	}
	.btn-outline-primary{
		border-color:#cbcbcb;
		&:hover{
			border-color:var(--primary);
		}
	}
	& > *{
		@include respond('phone-land') {
			margin-#{$dlab-pos-right}:5px !important;
			margin-bottom:10px;
			display: inline-block;
			vertical-align: baseline;
			margin-#{$dlab-pos-left}:0 !important;
		}
	}
}

.btn-close:focus {
    box-shadow: unset;
}

.list-style{
	li{
		list-style-type:unset;
	}
}

.content-body{
	&.default-height{
		min-height:calc(100vh - 60px);
	}
}

/* edit */
.author-profile{
	text-align:center;
	.card-body{
		padding: 0;
	}
	.author-media{
		position: relative;
		margin-#{$dlab-pos-left}: auto;
		margin-#{$dlab-pos-right}: auto;
		margin-bottom: 20px;
		display: inline-block;
		img{
			width: 130px;
			border-radius: 100%;
		}
	}
	.author-info{
		.title{
			font-size:15px;
			font-weight:500;
			margin-bottom: 0;
		}
		span{
			display:block;
			color: var(--text);
		}
	}
	.info-list{
		li{
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 15px;
			border-top: 1px solid var(--border);
			padding: 18px 30px;
			color: var(--text);
			a{
				color: var(--text);
			}
			span{
				color: var(--text);
				font-weight: 500;
			}
		}
	}
	.card-footer {
		padding: 30px;
		display: block;
		.form-control {
			height: 45px;
			border-color: var(--border);
			overflow:hidden;
			line-height: 34px;
		}
	}
	.upload-link {
		position: absolute;
		width: 35px;
		height: 35px;
		line-height: 32px;
		background:var(--primary);
		bottom: 0;
		#{$dlab-pos-left} :  0px;
		box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
		border-radius: 100%;
		color: #fff;
		overflow: hidden;
		border: 2px solid #fff;
		.update-flie {
			position: absolute;
			opacity: 0;
			z-index: 0;
			width: 100%;
			cursor: pointer;
			#{$dlab-pos-left} :  0;
			height: 100%;
		}
	}
}

// Social icons link
.post-input{
    margin-bottom: 1.875rem;
    .form-control{
        font-weight: 400;

    }
	.btn-social{
		font-size: 1.25rem;
		height: 3rem;
		display:inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		text-align:center;
		border-radius:$radius;
		color:$white;
		width: 3rem;
		@include respond ('phone'){
			font-size: 0.95rem;
			height: 2.2375rem;
			width: 2.2375rem;
			line-height: 2.275rem;
		}
		&.facebook{
			background-color: #3B5998;
		}
		&.google-plus{
			background-color: #DE4E43;
		}
		&.linkedin{
			background-color: #007BB6;
		}
		&.instagram{
			background-color: #8A5A4E;
		}
		&.twitter{
			background-color: #1EA1F3;
		}
		&.youtube{
			background-color: #CE201F;
		}
		&.whatsapp{
			background-color: #01C854;
		}
		i{
			margin:0!important;
		}
	}

}

.card-link{
	&:hover{
		color:var(--primary);
	}
}

.list-style-1{
	&.block{
		& > li{
			display: block;
		}
	}
	& > li{
		border-bottom: 1px dashed var(--border);
		padding: .75rem 0;
		display: flex;
		align-items: center;
	}
}

.add-wishlist-btn{
	i{
		font-size: 1rem;
	}
	span{
		font-weight: 500;
	}
	.fill{
		display: none;
		color: $danger;
	}
	&.active{
		.fill{
			display: inline-block;
		}
		.outline{
			display: none;
		}
	}
	&:hover{
		color: var(--primary);
	}
}


.pass-handle{
	cursor: pointer;
	.fa-eye{
		display: none;
	}
	&.active{
		.fa-eye{
			display: block;
		}
		.fa-eye-slash{
			display: none;
		}
	}
}

.custom-react-select{
	& > div:nth-child(4){
		& > div{
			background-color: #fff;
			& > div{
				background-color: #fff;
				cursor: pointer;
				@include transitionMedium;
				&:hover{
					background-color: var(--rgba-primary-1);
					color: #000;
				}
			}
		}
	}
	& > div {
		&:nth-child(3){
			height: 2.5rem;
			background-color: #fff;
			border-radius: 0.375rem;
			border-color: var(--border)!important;
		}
		&:nth-child(4){
			border-radius: 10px;
			min-width: 150px;
			overflow: hidden;
			box-shadow: 0px 10px 40px 0px rgba(21, 50, 93, 0.1);
		}
		& > div{
			&:first-child{
				div{
					font-size: 0.875rem;
					color: #6e6e6e;
				}
			}
			&:nth-child(2){
				& > span{
					display: none;
				}
			}
		}
	}
	&.img-select {
		& > div {
			&:nth-child(3){
				border: 0;
			}
		}
	}
}

.dataTables_wrapper .dataTables_paginate .paginate_button{
	box-sizing: border-box;
  	display: inline-block;
 	min-width: 1.5em;
}

.input-hasicon{
	.picker-suit{
		display: unset;
		.rs-input-group {
			height: 2.7rem;
			.rs-input::placeholder {
				color: var(--text-dark);
			}
			&:hover{
				border-color: var(--rgba-primary-5);
			}
			&:focus {
				border-color: var(--rgba-primary-5);
			}
		}
		.rs-input-group-addon {
			display: none;
		}
	}
	.icon {
		z-index: 3;
	}
}
.search-drop{
    width: 55px;
	margin: 0 5px;
	.search-drop-btn{
		padding: .5rem 1rem;
		border: 1px solid var(--border);
		background-color: transparent;
		font-weight: 400;
		color: var(--text);
	}
}

